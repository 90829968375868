import React, { useEffect, useState } from 'react';
import logoicon from '../Assets/images/logoicon.png'
import { ProgressBar} from 'react-bootstrap';
import { UsegetSaleInfo } from '../Hooks/useContract';
import { getAccount } from '../Hooks/useAccounts';
import Masp from './Modal/MaspModal';

const LaunchpadCont = (props) => {
    const[masp, setMasp] = useState(false)
    const [LaunchpadData,setLaunchpadData] = useState({})
    const [accountInfo, setAccountInfo] = useState(getAccount());
    const [yieldValue, setyield] =useState('')
    const [presaleaddress,setpresaleaddress] = useState(props?.data?.presaleAddress)
    const [progressBNB,setprogressBNB] = useState(0)
    const [progressUSDT,setprogressUSDT] = useState(0)
    const [tokensymbol,settokensymbol] = useState("")
    const [PoolDays, setPoolDays] =useState('')


    function showloader() {
        document.getElementById("loader_div").style.display = "block";
    }

    function hideloader()
    {
        document.getElementById("loader_div").style.display = "none";
    } 

   


    useEffect(() =>{
        console.log("data>>>",props?.data);
        showloader()
        getData(props?.data?.presaleAddress)
    },[presaleaddress])

    const getData =async(value) =>{
        try{
        var data = presaleaddress && value != "" ? await UsegetSaleInfo(value,accountInfo) : "";
        setLaunchpadData(data)
        var progressbnb = (parseInt(data?.bnbearnedcap)  / (parseInt(data?.hardCap)) *100).toFixed(2);
        var progressUSDT = (parseInt(data?.busdearnedcap) / (parseFloat(data?.busdhard))*100).toFixed(2);
        setprogressBNB(progressbnb)
        setprogressUSDT(progressUSDT)
        settokensymbol(data?.presaletokensymbol)
        hideloader()
        }catch(e){
            console.log("getData",e);
        }
        
             
           
const a = "0.05%"
const b ="2%"
const c = "15%"
const d = "0.05%"
const e ="2%"
const f = "15%"

if(props?.pid === 0)
{
  setyield(a)
  setPoolDays("180 Days")
 
}if(props?.pid === 1){
  setyield(b)
  setPoolDays("180 Days")
 
}if(props?.pid === 2){
  setyield(c)
  setPoolDays("365 Days")
 
}
if(props?.pid === 3){
    setPoolDays("90 Days")  
  setyield(d)
 
}
if(props?.pid === 4){
    setPoolDays("180 Days")
  setyield(e)
  
}
if(props?.pid === 5){
    setPoolDays("365 Days")
  setyield(f)
 
}
        
    }

    return(
        <div className='launchbox p-4 br-14 mt-5'>
             
            {masp && <Masp presaleaddress={presaleaddress} tokensymbol = {props?.data?.tokenSymbol} onDismiss={() => setMasp(false)}/>}
            {/* {LaunchpadData && LaunchpadData?.ispresaleopen ?  <button className='chocobtn'>On Process</button> : <button className='chocobtn'>{LaunchpadData && LaunchpadData?.isclaimable ? "Completed": "Stopped"}</button>} */}
        
    <div className='logocont'><img src={logoicon} />
     {/* <span>{props?.data?.tokenSymbol} </span>  */}
     <span className='textclr1 ml-4'>{PoolDays}</span></div>  
        <div className='bordbox p-4 br-14 my-3'>
            <div className='d-lg-flex jc-between align-items-center flex-1'>

            <div className='d-md-flex justify-content-around align-items-center w-full flex-1'>
                <div>
                    <p className='textclr mb-1'>Sale Price</p>
                    {/* <h6>1 BNB = {LaunchpadData && LaunchpadData?.pricePer || 0} {tokensymbol}</h6> */}
                    <h6>1 USDT = {LaunchpadData && LaunchpadData?.pricePerCrypto || 0} 
                     {/* {tokensymbol} */} GRUN
                    </h6>
                    <p className='textclr mt-3 mb-1'>Access</p>
                    <h6>Public</h6>
                </div>
                <div><p className='textclr mb-1'>Yield</p>
                <p className='yieldvalue'>{yieldValue}</p></div>
                
                <div>
                    <p className='textclr mb-1'>Presale HardCap</p>
                    {/* <h6>{LaunchpadData && LaunchpadData?.hardCap || 0} BNB & {LaunchpadData && LaunchpadData?.busdhard ||0} USDT</h6> */}

                    <h6> {LaunchpadData && LaunchpadData?.busdhard ||0} USDT</h6>
                </div>
            </div>

            <div className='d-md-flex justify-content-around align-items-center w-full flex-1'>
                <div>
                    <p className='textclr mb-1'>Min Allocation</p>
                    {/* <h6>{LaunchpadData && LaunchpadData?.minamount || 0} BNB & {LaunchpadData && LaunchpadData?.busdminamount || 0} USDT</h6> */}

                    <h6> {LaunchpadData && LaunchpadData?.busdminamount || 0} USDT</h6>
                </div>
                <div>
                    <p className='textclr mb-1'>Max Allocation</p>
                    {/* <h6>{LaunchpadData && LaunchpadData?.maxamount || 0} BNB & {LaunchpadData && LaunchpadData?.busdmaxamount ||0 } USDT</h6> */}

                    <h6> {LaunchpadData && LaunchpadData?.busdmaxamount ||0 } USDT</h6>
                </div>
                <div><p className='textclr mb-1'>Claimable 
                {/* {tokensymbol} */} GRUN
                </p>
                <p className='yieldvalue'>{parseFloat(LaunchpadData && LaunchpadData?.claimb).toFixed(5) || 0 } 
                {/* {tokensymbol} */}
                </p></div>
                
                </div>

            </div>
        </div>
        <div className='row mt-4'>
            {/* <div className='col-md-6 mb-4'>
                <h6>Progress ({LaunchpadData && LaunchpadData.bnbearnedcap || 0} / {LaunchpadData && LaunchpadData?.hardCap || 0} BNB ) {progressBNB|| 0} %</h6>
                <ProgressBar now={((parseInt(LaunchpadData && LaunchpadData?.bnbearnedcap)/parseInt(LaunchpadData && LaunchpadData?.hardCap))*100)} />
            </div> */}
            <div className=' mb-4'>
                <h6>Progress ({LaunchpadData && LaunchpadData.busdearnedcap || 0} / {LaunchpadData && LaunchpadData?.busdhard || 0} USDT ) {progressUSDT || 0} %</h6>
            <ProgressBar now={((parseInt(LaunchpadData && LaunchpadData?.busdearnedcap)/parseInt(LaunchpadData && LaunchpadData?.busdhard))*100)} />
            </div>
        </div>
        {LaunchpadData?.ispresaleopen ?
        <p className='textclr mt-4'>End Date: {LaunchpadData && LaunchpadData?.saleEndTime} UTC</p> : <>
         <p className='textclr mt-4'>Sale Not Live</p></>
        }
      
        {/* <div className='text-end'>
        {(accountInfo == "" || accountInfo == null)  ?    <button className='btn getstarted'>Unlock Wallet</button>:  
        <button className='btn getstarted' onClick={() => setMasp(true)}>Buy</button>}
        </div> */}

        <div className='d-flex justify-content-between'>
        <div className='text-endzz'>
        {(accountInfo == "" || accountInfo == null)  ?    <button className='btn getstarted'>Unlock Wallet</button>:  
        <button className='btn getstarted' onClick={() => setMasp(true)}>Buy</button>}
        </div>
        <div>
        {LaunchpadData && LaunchpadData?.ispresaleopen ?  <button className='chocobtn'>On Process</button> : <button className='chocobtn'>{LaunchpadData && LaunchpadData?.isclaimable ? "Completed": "Stopped"}</button>}
        </div>
        </div>

    

        </div>
    )
}

export default LaunchpadCont