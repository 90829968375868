import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import newdetail from "../Assets/images/news.jpg";
// import Token from "../Assets/images/token.jpg"
import Token from "../Assets/images/newdetail.jpg"
import Token1 from "../Assets/images/newdetail1.jpg"
import { getArticles, getNewsData, getNewsDesData, getTotalArticleImage } from '../Hooks/UseBackend';
import { backurl } from '../Config/env';

import bannerimg from '../Assets/images/grunimg/1.png';
import bannerimg1 from '../Assets/images/grunimg/img1.png';

import logoss from "../Assets/images/logo.png";
import grunlogo from "../Assets/images/grunimg/grun_logo.png";
// import Token1 from "../Assets/images/token1.jpg"
const News = () => {


    const [newsdata,setnewsdata] = useState([])
    const [newsdesdata,setnewsdesdata] = useState([])
    const [articledata,setarticledata] = useState([])
    const  responsive2 = {
        0: {
            items: 1,
        },
        424:{
            items: 1,
        },
        576:{
            items: 1,
        },
        768: {
            items: 1,
        },
        1200: {
            items: 1,
        },
    }

    const  responsive3 = {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        1200: {
            items: 3,
        },
    }

useEffect(() =>{
    getData()
},[]
)

    const getData =async() =>{
       
    const news = await getNewsData();
    setnewsdata(news)

    const newsdes = await getNewsDesData();
    setnewsdesdata(newsdes)


    const articles = await getArticles();
    
    // setarticledata(articles)
    const Imagedata = await getTotalArticleImage();
    var datsd = articles.map((it,index) => { it.image = Imagedata[index]
    
    return it;
    })
        
    
    setarticledata(datsd)
         
        
      }
    return(
        <div className="news">
            <Header />
            <div className='newshead'>

            
            <OwlCarousel items={1} className="owl-theme"  loop={false} nav={true} margin={0} autoplay ={false} responsive={responsive2} dots={false}> 
            {newsdata && newsdata.map((item) =>
            (item.status == true ? 
                <div className='items'>
                <div className='newshead'>

          
           
                <div className='newdetail'>
                    {/* <img src={backurl + `/Images/${item?.logoURI}`}/> */}
                    {/* <img className='darkimg' src={bannerimg}/> */}
                    <img className='lightimg' src={bannerimg1}/>
                </div>

                </div>
                <div className='container container-theme mt-4'>
                  <div className='itemsfles'>
                     <h2 className='pt-5'>{item?.Heading}</h2> 
                     <p>{item?.Description}</p>
                    {/* <h2>News</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p> */}
                    <div className='d-flex'>
                  <a target='blank' href="/"><button className='btn getstarted mt-xs-4'>Get Started</button></a>
                  <a target='blank' className='ms-3' href="/"><button className='btn getstarted1 mt-xs-4'>Learn More</button></a>
                </div>
                       
                    </div>
                    </div>
                </div> : <></>)
            )}   
                
            </OwlCarousel>
            </div>
            <div className='Editor_pick firstsec'>
            <div className='container container-theme mt-4'>
                <h2 className='editss'>News</h2>
                <div className='row'>
                    {/* {[...Array(6)].map(()=>( */}
                    {newsdesdata && newsdesdata.map((item) =>
                    (item.status == true ? 
                        <div className='col-lg-4 col-md-6 col-sm-6 col-12 col_token mb-4'>

                        <a href={`/newsdetails/${item._id}`}> 
                        <div className='token_list'>
                        <div className='tokenimg'>
                            <img src={backurl + `/Images/${item?.logoURI}`}/>
                            {/* <img src={logoss}/> */}
                        </div>
                        <div className='token_items'>
                            
                            <p className='cryptoo mb-0'> News</p>
                             <h2>{item?.Title}</h2>
                            <p>{item?.Heading}</p>
                            {/* <h2>Lorem ipsum dolor sit amet, consetetur content.</h2>
                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem</p> */}
                        </div>
                    </div>
                    </a>
                        </div> : <></>)
                        
                        
                     )} 
                </div>
                
                </div>
            </div>
            
            <section className='Editor_pick launchpad' id='latest_article'>
            <div className='container container-theme mt-4'>
                <h2 className='editss'>Latest Articles</h2>


                </div>
                <div className='container carouselcontainer'>
                    {articledata && articledata.length>0 && 
                <OwlCarousel items={3} className="owl-theme latestarticle" loop={false} nav={true} margin={10} autoplay ={false} responsive={responsive3} dots={false}> 
                {articledata && articledata.map((item) =>
                  item.status == true ?
                 <a href={`/latestarticles/${item && item?._id}`} className="col_token"> 
                 <div className='token_list '>
                 <div className='tokenimg'>
                 <img src={backurl + `/Images/${item && item?.logoURI}`}/>
                 </div>
                 <div className='token_items'>
                     <h2>{item && item?.Title}</h2>
                     <p className='mb-3'>{item && item?.Heading}</p>
                        {/* <h2>Lorem ipsum dolor sit amet, consetetur content.</h2>
                            <p className='mb-3'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam diam voluptua.</p> */}
                 </div>
             </div>
                  </a> : <></>
                )}
                </OwlCarousel>
                
                    }

                   
                  
                   

                        {/* {articledata && articledata[1]?.status == true ?
                        <a href={`/latestarticles/${articledata && articledata[1]?._id}`} className="col_token">
                        <div className='token_list '>
                        <div className='tokenimg'>
                        <img src={backurl + `/Images/${articledata && articledata[1]?.logoURI}`}/>
                        </div>
                        <div className='token_items'>
                            <h2>{articledata && articledata[1]?.Title}</h2>
                            <p>{articledata && articledata[1]?.Heading}</p>
                        </div>
                    </div>
                         </a> : <></>}
                        {articledata && articledata[2]?.status == true ?  
                        <a href={`/latestarticles/${articledata && articledata[2]?._id}`} className="col_token"> 
                        <div className='token_list '>
                        <div className='tokenimg'>
                        <img src={backurl + `/Images/${articledata && articledata[2]?.logoURI}`}/>
                        </div>
                        <div className='token_items'>
                            <h2>{articledata && articledata[2]?.Title}</h2>
                            <p>{articledata && articledata[2]?.Heading}</p>
                        </div>
                    </div>
                        </a> : <></>}
                        {articledata && articledata[3]?.status == true ?
                        <a href={`/latestarticles/${articledata && articledata[3]?._id}`} className="col_token">
                        <div className='token_list'>
                        <div className='tokenimg'>
                        <img src={backurl + `/Images/${articledata && articledata[3]?.logoURI}`}/>
                        </div>
                        <div className='token_items'>
                            <h2>{articledata && articledata[3]?.Title}</h2>
                            <p>{articledata && articledata[3]?.Heading}</p>
                        </div>
                    </div>
                        </a> : <></>} */}
                </div>
                
                
            
            </section>
            <Footer />
        </div>
    )
}

export default News