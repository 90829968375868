import React, { useEffect, useState, useRef } from 'react'
import Footer from './Footer'
import Header from './Header'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Img1 from "../Assets/images/grunimg/partnerlogo.png"
import Img2 from "../Assets/images/grunimg/partnerlogo1.png"
import Img3 from "../Assets/images/grunimg/partnerlogo.png"
import Img4 from "../Assets/images/grunimg/partnerlogo1.png"
import Img5 from "../Assets/images/grunimg/partnerlogo.png"
import Img6 from "../Assets/images/grunimg/partnerlogo1.png"
import Img7 from "../Assets/images/grunimg/partnerlogo.png"
import Img8 from "../Assets/images/grunimg/partnerlogo1.png"
import Img9 from "../Assets/images/img9.svg"
import Img10 from "../Assets/images/img10.svg"
import Img11 from "../Assets/images/img11.svg"
import Img12 from "../Assets/images/img12.svg"
import Img13 from "../Assets/images/img13.svg"
import Img14 from "../Assets/images/img14.svg"
import Img15 from "../Assets/images/img15.svg"
import Img16 from "../Assets/images/img16.svg"
import Img17 from "../Assets/images/img17.svg"
import Img18 from "../Assets/images/img18.svg"
import Img19 from "../Assets/images/img19.svg"
import Img20 from "../Assets/images/img20.svg"
import Img21 from "../Assets/images/img21.svg"
import banner from "../Assets/images/banner.png"
import whylabel from "../Assets/images/whylabel.png"
import files from "../Assets/images/files.png"
import trading from "../Assets/images/trading.png"
import { getAboutData, getFeatureData, getFeaturedesData, getLaunchpadData, getRoadmapdesData, getSettingsData } from '../Hooks/UseBackend';
import { backurl } from '../Config/env';
import { UsegetsingleSaleInfo } from '../Hooks/useContract';
import toast, { Toaster }  from 'react-hot-toast'

import grunimg from '../Assets/images/grunimg/grunwelcomeimg.png';
import grundo from '../Assets/images/grunimg/grundo.png';
import rightarrow from '../Assets/images/grunimg/rightarrow.png';
import blackarrow from '../Assets/images/grunimg/blackarrow.png';
import feature from '../Assets/images/grunimg/feature.png';
import sideimg from '../Assets/images/grunimg/sideimg.png';
import nutss from '../Assets/images/grunimg/nuts.png';
import nutssmall from '../Assets/images/grunimg/nutssmall.png';
import no1 from '../Assets/images/grunimg/no1.png';
import CountUp from 'react-countup';
import ReactReadMoreReadLess from "react-read-more-read-less";
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import axios from 'axios'

const Land = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const bannerSection = useRef(null);
  const scrollDown = (ref) => {
    window.scrollTo({
        top: ref.current.offsetTop,
        behavior: 'smooth',
    });
};
  const [aboutdata, setaboutdata] = useState([])
  const [featuredata, setfeaturedata] = useState([])
  const [featuredesdata, setfeaturedesdata] = useState([])

  const [roadmapdata, setroadmapdata] = useState([])
  const [totalLaunchPadlength, settotalLaunchPadlength] = useState([])
  const [singledata1, setsingledata1] = useState({})
  const [singledata2, setsingledata2] = useState({})
  const [singledata3, setsingledata3] = useState({});
  const [cmsdata, setCmsData] = useState([]);
  const [error, setError] = useState("")
  const [email, setEmail] = useState("")
  console.log("cms_data", cmsdata.length)
  // const myLongText = cmsFilter("landing_banner06")?.SubDescription1 == undefined ? "" : cmsFilter("landing_banner06")?.SubDescription1;

  // const myLongText1 = cmsFilter("landing_banner06")?.SubDescription2 == undefined ? "" : cmsFilter("landing_banner06")?.SubDescription2;

  // const myLongText3 = cmsFilter("landing_banner06")?.SubDescription3 == undefined ? "" : cmsFilter("landing_banner06")?.SubDescription3;

  const responsive1 = {
    0: {
      items: 1,
    },
    424: {
      items: 1,
    },
    576: {
      items: 2,
    },
    768: {
      items: 3,
    },
    1200: {
      items: 4,
    },
  }
  useEffect(() => {
    getData();

  }, [])


  const getData = async () => {

    var data = await getAboutData();
    setaboutdata(data)
    var feature = await getFeatureData();
    setfeaturedata(feature);
    var featruedes = await getFeaturedesData();
    setfeaturedesdata(featruedes)
    var roadmapdes = await getRoadmapdesData();
    setroadmapdata(roadmapdes)
    var data = await getLaunchpadData();

    settotalLaunchPadlength(data)
    var singledata = await UsegetsingleSaleInfo(data[0]?.presaleAddress);
    setsingledata1(singledata);
    var singledata2 = await UsegetsingleSaleInfo(data[1]?.presaleAddress);

    setsingledata2(singledata2);
    var singledata3 = await UsegetsingleSaleInfo(data[2]?.presaleAddress);
    setsingledata3(singledata3);


  }


  useEffect(() => {

    getCmsData();
  }, []);

  const getCmsData = async () => {

    try {
      axios
        .get(backurl + "/api/get/home")
        .then(res => {
          // console.log("Ressssssssssss", res?.data)
          setCmsData(res?.data)

        })
        .catch()
    } catch (err) {
      console.log("Errrrrrr", err)
    }
  }

  const cmsFilter = (data) => {
    try {
      let filterdata = cmsdata?.find((el) => el.Identifier == data && el.Status == "true");
      // console.log("filterdata", filterdata)
      if(filterdata){
        return filterdata;
      }
    } catch (err) {

    }
  }
  
  const Subscribe = async (data) => {

      try {
        console.log("emaildddddddddd jeje", email)
      let validates = await ValidateEmail(email);
      let objlen = Object.values(validates).length
      if (objlen == 0) {
        var subsc = {
          email : email
        }
        console.log("emtdsaghkjshfgasdd")
        axios
          .post(backurl + "/api/user/subscribers", subsc)
          .then(async (res) => {
            console.log("respond_data", res,res.data.status == true,res.data.message)
            if (res.status === 200 && res.data.status == true) {
              console.log("toast_1 jeje")
              setEmail("")
              toast.success(`${res.data.message}`, { style: { minWidth: '300px', minHeight: '55px' } })
              // await sleep(1000)
              // window.location.reload()

            } else {
              console.log("toast_2 jeje")
              toast.error(`${res.data.message}`, { style: { minWidth: '300px', minHeight: '55px' } })
              // await sleep(1000)
            }
          })
          .catch()
      }


    } catch (err){
      console.log("errrrrrrr", err)
    }
  }


  const ValidateEmail = (email) => {
    console.log("enter email data jeje", email)
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    let error = {};
    if (email != "") {
      console.log("email jeje", !regex.test(email))
      if (!regex.test(email)) {
        error.email = "You have entered an invalid email address!"
      }
    } else {
      error.email = "Email is required!"
    }
    console.log("erremail", error)
    setError(error)
    return error
  }

  return (
    cmsdata && cmsdata?.length > 0 ?
    <>
    {console.log("enter this1 1111")}
    <div className='land'>
    <div><Toaster/></div> 
      <Header />
      <div className='container container-theme'>

        <section className='banner'>
          <img src={nutss} className='nutsss okeyy' alt='nutss' />
          <img src={nutss} className='nutsss1 okeyy' alt='nutss' />
          <img src={nutss} className='nutsss2 okeyy' alt='nutss' />
          <img src={nutss} className='nutsss3 okeyy' alt='nutss' />
          <img src={nutssmall} className='nutssmall okeyy' alt='nutss' />
          <div className='row align-items-center'>
            <div className='col-md-6' data-aos="fade-up" data-aos-duration="1500">
              <div className='ban-cont'>
                {console.log("enter thos")}
                {/* <p className='themeclr'>{aboutdata && aboutdata[0]?.Title}</p> */}
                {/* <h1 className='heading'>Welcome to <span className='titles'>Grun</span><span className='subtitles'>Coin</span></h1> */}
                <h1 className='firsth1'>{cmsFilter("landing_banner01")?.Title == undefined ? "" : cmsFilter("landing_banner01")?.Title}</h1>
                <p>{cmsFilter("landing_banner01")?.Description == undefined ? "" : cmsFilter("landing_banner01")?.Description}</p>
                {/* <a target='_blank' href="https://www.travelpayouts.com/"> */}
                  <button className='btn getstarted mt-4'  onClick={() => { scrollDown(bannerSection) }}>Get Started</button>
                  {/* </a> */}
              </div>
            </div>
            <div className='col-md-6 d-none d-md-block' data-aos="fade-down" data-aos-duration="1500">
              <div className='bannerimg text-center'>
                {/* <img src={grunimg} /> */}
                {cmsFilter("landing_banner01")?.ImageURI1 == undefined ? "" : < img src={backurl + "/Images/" + cmsFilter("landing_banner01")?.ImageURI1}/>}
              </div>
            </div>
          </div>
        </section>

        <section className='memberlist'>
          <div className='row'>
            <div className='col-md-3 col-sm-6'>
              <h1>$<CountUp duration={5.75} end={42} />M</h1>
              <p>{cmsFilter("landing_banner02")?.Heading1 == undefined ? "" : cmsFilter("landing_banner02")?.Heading1}</p>
            </div>
            <div className='col-md-3 col-sm-6'>
              <h1>$<CountUp duration={5.75} end={56} />M</h1>
              <p>{cmsFilter("landing_banner02")?.Heading2 == undefined ? "" : cmsFilter("landing_banner02")?.Heading2}</p>
            </div>
            <div className='col-md-3 col-sm-6'>
              <h1><CountUp duration={5.75} end={90} />+</h1>
              <p>{cmsFilter("landing_banner02")?.Heading3 == undefined ? "" : cmsFilter("landing_banner02")?.Heading3}</p>
            </div>
            <div className='col-md-3 col-sm-6'>
              <h1><CountUp duration={5.75} end={45000} /></h1>
              <p>{cmsFilter("landing_banner02")?.Heading4 == undefined ? "" : cmsFilter("landing_banner02")?.Heading4}</p>
            </div>
          </div>
        </section>

        <section className='banner whatdoes'>
          <div className='row align-items-center'>
            <div className='col-md-6 d-none d-md-block' data-aos="fade-down" data-aos-duration="1500">
              <div className='bannerimg text-center'>
                {/* <img src={grundo} /> */}
                {cmsFilter("landing_banner03")?.ImageURI1 == undefined ? "" : < img src={backurl + "/Images/" + cmsFilter("landing_banner03")?.ImageURI1}/>}
              </div>
            </div>
            <div className='col-md-6' data-aos="fade-up" data-aos-duration="1500">
              <div className='ban-cont'>
                <p className='themeclr'>{cmsFilter("landing_banner03")?.Heading1 == undefined ? "" : cmsFilter("landing_banner03")?.Heading1}</p>
                <h1 className='innerheading'>{cmsFilter("landing_banner03")?.Title == undefined ? "" : cmsFilter("landing_banner03")?.Title}</h1>
                <p>{cmsFilter("landing_banner03")?.Description == undefined ? "" : cmsFilter("landing_banner03")?.Description}</p>
                <div className='d-flex'>
                   {/* <a target='_blank' href="https://www.travelpayouts.com/"> */}
                    <button className='btn getstarted mt-4' onClick={() => { scrollDown(bannerSection) }}>Get Started</button>
                    {/* </a> */}
                  <a className='ms-3' href="/launchpad"><button className='btn getstarted1 mt-4'>Buy Gruncoin now</button></a>
                </div>
                {/* <a target='blank' href="https://labelm.io/launchpad"><button className='btn getstarted'>Get Started</button></a> */}
              </div>
            </div>
          </div>
        </section>

        <section className='marketcapital bgtheme1 br-14 p-4 text-dark' data-aos="zoom-in" data-aos-duration="1500">
          {/* <h3 className='bold'>Market Cappitalization</h3>
          <p>$902,214,730</p> */}
          <h3 className='bold mb-3'>Information</h3>
          <div className='row'>
            <div className='col-md-4 col-sm-6 mb-4'>
              <div className='infos'>
                <p className="mb-2">Sell 1 (90 days Locked  staking)</p>
                <p className="mb-2">Price:  1 USDT = {singledata1 && singledata1?.pricePerCrypto} GRUN</p>
                <p className="mb-2">Reward: 0.05%</p>
                <p className="mb-2">Sale : {singledata1 && singledata1.ispresaleopen ? "Ongoing " : "Sold Out"}</p>
                {/* <a target='blank' href="https://labelm.io/launchpad"> <button className='btn getstarted dark mt-3'>Get Started</button></a> */}

              </div>
            </div>
            <div className='col-md-4 col-sm-6 mb-4'>
              <div className='infos'>
                <p className="mb-2">Sell 2 (180 days Locked  staking)</p>
                <p className="mb-2">Price: 1 USDT = {singledata2 && singledata2.pricePerCrypto} GRUN</p>
                <p className="mb-2">Reward: 2%</p>
                <p className="mb-2">Sale: {singledata2 && singledata2.ispresaleopen ? "Ongoing " : "Sold Out"}</p>
                {/* <a target='blank' href="https://labelm.io/launchpad"> <button className='btn getstarted dark mt-3'>Get Started</button></a> */}

              </div>
            </div>
            <div className='col-md-4 mb-4'>
              <div className='infos'>
                <p className="mb-2">Sell 3 (365 days Locked  staking)</p>
                <p className="mb-2">Price: 1 USDT = {singledata3 && singledata3?.pricePerCrypto} GRUN</p>
                <p className="mb-2">Reward: 15%</p>
                <p className="mb-2">Sale: {singledata3 && singledata3.ispresaleopen ? "Ongoing " : "Sold Out"}</p>


                {/* <a target='blank' href="https://labelm.io/launchpad"> <button className='btn getstarted dark mt-3'>Get Started</button></a> */}

              </div>
            </div>
          </div>
        </section>

        <section className='howdoes ban-cont'  ref={bannerSection}>
          <div>
            <h1 className='innerheading text-center'>{cmsFilter("landing_banner04")?.Title == undefined ? "" : cmsFilter("landing_banner04")?.Title}</h1>
            <p className='text-center'>{cmsFilter("landing_banner04")?.Heading1 == undefined ? "" : cmsFilter("landing_banner04")?.Heading1}</p>
            <div className='row pt-4'>
              <div className='col-lg-4 col-md-6'>
                <div className='row pt-3 align-items-center'>
                  <div className='col-md-3 col-3'>
                    {/* <img className='w-100' src={no1}/> */}
                   {cmsFilter("landing_banner04")?.SubTitle1 == undefined ? "" : <button className='btn stepbtn'>1</button>}
                  </div>
                  <div className='col-md-7 col-9'>
                    {cmsFilter("landing_banner04")?.SubTitle1 == undefined ? "" : <p className='stepno mb-2'>Step 1</p>}
                    <p className='mb-0'>{cmsFilter("landing_banner04")?.SubTitle1 == undefined ? "" : cmsFilter("landing_banner04")?.SubTitle1}</p>
                  </div>
                  <div className='col-md-2'>
                    {cmsFilter("landing_banner04")?.SubTitle1 == undefined ? "" :  <img className='w-100' src={rightarrow} />}
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='row pt-3 align-items-center'>
                  <div className='col-md-3 col-3'>
                    {/* <img className='w-100' src={no1}/> */}
                    {cmsFilter("landing_banner04")?.SubTitle1 == undefined ? "" : <button className='btn stepbtn1'>2</button>}
                  </div>
                  <div className='col-md-7 col-9'>
                   {cmsFilter("landing_banner04")?.SubTitle1 == undefined ? "" : <p className='stepno mb-2'>Step 2</p>}
                    <p className='mb-0'>{cmsFilter("landing_banner04")?.SubTitle2 == undefined ? "" : cmsFilter("landing_banner04")?.SubTitle2}</p>
                  </div>
                  <div className='col-md-2'>
                    {cmsFilter("landing_banner04")?.SubTitle1 == undefined ? "" : <img className='w-100' src={rightarrow} />}
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='row pt-3'>
                  <div className='col-md-3 col-3'>
                    {/* <img className='w-100' src={no1}/> */}
                    {cmsFilter("landing_banner04")?.SubTitle1 == undefined ? "" : <button className='btn stepbtn1'>3</button>}
                  </div>
                  <div className='col-md-9 col-9'>
                   {cmsFilter("landing_banner04")?.SubTitle1 == undefined ? "" : <p className='stepno mb-2'>Step 3</p>}
                    <p className='mb-0'>{cmsFilter("landing_banner04")?.SubTitle3 == undefined ? "" : cmsFilter("landing_banner04")?.SubTitle3}</p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className='howdoes ban-cont'>
          <div>
            <h1 className='innerheading text-center'>How does it work?</h1>
            <p className='text-center'>Simple in 3 quick and easy steps.</p>
            <div className='maxing'>
              <div className='row pt-3 pb-3'>
                <div className='col-md-4 d-flex align-items-center'>
                  <div className='stepss'>
                    <p className='mb-0 numberstep'>1</p>
                  </div>
                  <div className='ms-3'>
                    <p className='stepno mb-2'>Step 1</p>
                    <p className='mb-0'>Download the app. Or stay on the website.</p>
                  </div>
                  <div className='ms-3'>
                    <img src={rightarrow} />
                  </div>
                </div>
                <div className='col-md-4 d-flex align-items-center'>
                  <div className='stepss stepss2'>
                    <p className='mb-0 numberstep'>2</p>
                  </div>
                  <div className='ms-3'>
                    <p className='stepno mb-2'>Step 2</p>
                    <p className='mb-0'>Buy Gruncoin with crypto USDT.</p>
                  </div>
                  <div className='ms-3'>
                    <img src={rightarrow} />
                  </div>
                </div>
                <div className='col-md-4 d-flex align-items-center'>
                  <div className='stepss stepss3'>
                    <p className='mb-0 numberstep'>3</p>
                  </div>
                  <div className='ms-3'>
                    <p className='stepno mb-2'>Step 3</p>
                    <p className='mb-0'>Click on the logo of our partners. And let us know what you like to order or reserve via mail or WhatsApp or telegram or call back.</p>
                  </div>
                  <div className='ms-3'>
                    <img className='blackarrowimg' src={blackarrow} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}


        
        <section className='logos_sec partner ban-cont'>
          <h1 className='innerheading text-center'>{cmsFilter("landing_banner05")?.Title == undefined ? "" : cmsFilter("landing_banner05")?.Title}</h1>
          <p className='text-center smallsub mb-4'>{cmsFilter("landing_banner05")?.Description == undefined ? "" : cmsFilter("landing_banner05")?.Description}</p>

          <div className='row' data-aos="zoom-in" data-aos-duration="1500">
            <div className='col-6 col-md-3 col-sm-4 mt-5'>
              {(cmsFilter("landing_banner05")?.ImageLink1 != undefined || cmsFilter("landing_banner05")?.ImageURI1 != undefined ) ? 
               <> { <a href={cmsFilter("landing_banner05")?.ImageLink1 != undefined ? cmsFilter("landing_banner05")?.ImageLink1 : <>{}</> } target='_blank'>< img className='img-fluid' src={cmsFilter("landing_banner05")?.ImageURI1 != undefined ? backurl + "/Images/" + cmsFilter("landing_banner05")?.ImageURI1 : ""}/></a>}  </> : <>  </> }
                
            </div>
            <div className='col-6 col-md-3 col-sm-4 mt-5'>
              {(cmsFilter("landing_banner05")?.ImageLink2 != undefined || cmsFilter("landing_banner05")?.ImageURI2 != undefined ) ? 
               <> { <a href={cmsFilter("landing_banner05")?.ImageLink2 != undefined ? cmsFilter("landing_banner05")?.ImageLink2 : "" } target='_blank'>< img className='img-fluid' src={cmsFilter("landing_banner05")?.ImageURI2 != undefined ? backurl + "/Images/" + cmsFilter("landing_banner05")?.ImageURI2 : ""}/></a>}  </> : <>  </> }
                
            </div>
            <div className='col-6 col-md-3 col-sm-4 mt-5'>
              {(cmsFilter("landing_banner05")?.ImageLink3 != undefined || cmsFilter("landing_banner05")?.ImageURI3 != undefined ) ? 
               <> { <a href={cmsFilter("landing_banner05")?.ImageLink3 != undefined ? cmsFilter("landing_banner05")?.ImageLink3 : "" } target='_blank'>< img className='img-fluid' src={cmsFilter("landing_banner05")?.ImageURI3 != undefined ? backurl + "/Images/" + cmsFilter("landing_banner05")?.ImageURI3 : ""}/></a>}  </> : <>  </> }
                
            </div>
            <div className='col-6 col-md-3 col-sm-4 mt-5'>
              {(cmsFilter("landing_banner05")?.ImageLink4 != undefined || cmsFilter("landing_banner05")?.ImageURI4 != undefined ) ? 
               <> { <a href={cmsFilter("landing_banner05")?.ImageLink4 != undefined ? cmsFilter("landing_banner05")?.ImageLink4 : "" } target='_blank'>< img className='img-fluid' src={cmsFilter("landing_banner05")?.ImageURI4 != undefined ? backurl +"/Images/" + cmsFilter("landing_banner05")?.ImageURI4 : ""}/></a>}  </> : <>  </> }
                
            </div>
            <div className='col-6 col-md-3 col-sm-4 mt-5'>
              {(cmsFilter("landing_banner05")?.ImageLink5 != undefined || cmsFilter("landing_banner05")?.ImageURI5 != undefined ) ? 
               <> { <a href={cmsFilter("landing_banner05")?.ImageLink5 != undefined ? cmsFilter("landing_banner05")?.ImageLink5 : "" } target='_blank'>< img className='img-fluid' src={cmsFilter("landing_banner05")?.ImageURI5 != undefined ? backurl + "/Images/" + cmsFilter("landing_banner05")?.ImageURI5 : ""}/></a>}  </> : <>  </> }
                
            </div>
            <div className='col-6 col-md-3 col-sm-4 mt-5'>
              {(cmsFilter("landing_banner05")?.ImageLink6 != undefined || cmsFilter("landing_banner05")?.ImageURI6 != undefined ) ? 
               <> { <a href={cmsFilter("landing_banner05")?.ImageLink6 != undefined ? cmsFilter("landing_banner05")?.ImageLink6 : "" } target='_blank'>< img className='img-fluid' src={cmsFilter("landing_banner05")?.ImageURI6 != undefined ? backurl + "/Images/" + cmsFilter("landing_banner05")?.ImageURI6 : ""}/></a>}  </> : <>  </> }
                
            </div>
            <div className='col-6 col-md-3 col-sm-4 mt-5'>
              {(cmsFilter("landing_banner05")?.ImageLink7 != undefined || cmsFilter("landing_banner05")?.ImageURI7 != undefined ) ? 
               <> { <a href={cmsFilter("landing_banner05")?.ImageLink7 != undefined ? cmsFilter("landing_banner05")?.ImageLink7 : "" } target='_blank'>< img className='img-fluid' src={cmsFilter("landing_banner05")?.ImageURI7 != undefined ? backurl + "/Images/" + cmsFilter("landing_banner05")?.ImageURI7 : ""}/></a>}  </> : <>  </> }
                
            </div>
            <div className='col-6 col-md-3 col-sm-4 mt-5'>
              {(cmsFilter("landing_banner05")?.ImageLink8 != undefined || cmsFilter("landing_banner05")?.ImageURI8 != undefined ) ? 
               <> { <a href={cmsFilter("landing_banner05")?.ImageLink8 != undefined ? cmsFilter("landing_banner05")?.ImageLink8 : "" } target='_blank'>< img className='img-fluid' src={cmsFilter("landing_banner05")?.ImageURI8 != undefined ? backurl + "/Images/" + cmsFilter("landing_banner05")?.ImageURI8 : ""}/></a>}  </> : <>  </> }
                
            </div>
            
          </div>

          <div className='logogrid'>
            {/* <img src={Img1} />
            <img src={Img2} />
            <img src={Img3} />
            <img src={Img4} />
            <img src={Img5} />
            <img src={Img6} />
            <img src={Img7} />
            <img src={Img8} /> */}
            {/* <img src={Img9} />
            <img src={Img10} />
            <img src={Img11} />
            <img src={Img12} />
            <img src={Img13} />
            <img src={Img14} />
            <img src={Img15} />
            <img src={Img16} />
            <img src={Img17} />
            <img src={Img18} />
            <img src={Img19} />
            <img src={Img20} />
            <img src={Img21} /> */}

          </div>
        </section>

        <section className='feature'>
          <div className='text-center'>
            {/* <p className='themeclr'>{featuredata && featuredata[0]?.Title}</p> */}
            <h1 className='innerheading'>{cmsFilter("landing_banner06")?.Title == undefined ? "" : cmsFilter("landing_banner06")?.Title}</h1>
            <p className='smallp'>{cmsFilter("landing_banner06")?.Description == undefined ? "" : cmsFilter("landing_banner06")?.Description}</p>

          </div>
          <div className='row mt-5 justify-content-center'>
            <div className='col-md-5' data-aos="zoom-in-down" data-aos-duration="1500">
              <div className='features-list text-center p-4'>
                <img className="mb-3" src={feature} />
                {/* <img src={files} /> */}
                {/* <h4>{featuredesdata && featuredesdata[0]?.Heading}</h4> */}
                <h4 className=' themesub'>{cmsFilter("landing_banner06")?.SubTitle1 == undefined ? "" : cmsFilter("landing_banner06")?.SubTitle1}</h4>
                {/* <p>{featuredesdata && featuredesdata[0]?.Description} </p> */}
                <p className='mt-3'>
                  <ReactReadMoreReadLess
                    charLimit={214}
                    readMoreText={"Learn more ▼"}
                    readLessText={"Learn less ▲"}
                  >
                    {cmsFilter("landing_banner06")?.SubDescription1 == undefined ? "" : cmsFilter("landing_banner06")?.SubDescription1}
                  </ReactReadMoreReadLess>
                </p>
              </div>
            </div>
            <div className='col-md-5' data-aos="zoom-in-down" data-aos-duration="1500">
              <div className='features-list text-center p-4'>
                <img className="mb-3" src={feature} />
                {/* <img src={files} /> */}
                {/* <h4>{featuredesdata && featuredesdata[1]?.Heading}</h4> */}
                <h4 className='  themesub'>{cmsFilter("landing_banner06")?.SubTitle2 == undefined ? "" : cmsFilter("landing_banner06")?.SubTitle2}</h4>
                {/* <p>{featuredesdata && featuredesdata[1]?.Description} </p> */}
                <p className='mt-3'>
                  <ReactReadMoreReadLess
                    charLimit={210}
                    readMoreText={"Learn more ▼"}
                    readLessText={"Learn less ▲"}
                  >
                    {cmsFilter("landing_banner06")?.SubDescription2 == undefined ? "" : cmsFilter("landing_banner06")?.SubDescription2}
                  </ReactReadMoreReadLess>
                </p>
              </div>
            </div>
            {/* <div className='col-md-4'>
            <div className='features-list text-center p-4'>
                 <img  className="mb-3"src={backurl + `/Images/${featuredesdata && featuredesdata[2]?.logoURI}`} />
                    <h4>{featuredesdata && featuredesdata[2]?.Heading}</h4>
                    <p>{featuredesdata && featuredesdata[2]?.Description} </p>
                 </div>
            </div> */}
          </div>
        </section>
        <section className='banner trading container container_custom'>
          <div className='row align-items-center'>
            <div className='col-md-6' data-aos="fade-up" data-aos-duration="1500">
              <div className='ban-cont'>
                {/* <p className='themeclr'>{aboutdata && aboutdata[2]?.Title}</p> */}
                {/* <h1 className='innerheading'>{aboutdata && aboutdata[2]?.Heading}</h1> */}
                {/* <h1 className='innerheading'>"<span className='subtitles'>99.9% </span>More Energy Efficient"</h1> */}
                <h1 className='firsth1'>{cmsFilter("landing_banner06")?.SubTitle3 == undefined ? "" : cmsFilter("landing_banner06")?.SubTitle3} </h1>
                {/* <p className='mt-3'>Gruncoin is a great choise for anyone looking to invest in gold or use cryptocurrency for everyday purchases with 50% discount.</p> */}
                <p className='mt-3'>
                  <ReactReadMoreReadLess
                    charLimit={126}
                    readMoreText={"Learn more ▼"}
                    readLessText={"Learn less ▲"}>
                    {cmsFilter("landing_banner06")?.SubDescription3 == undefined ? "" : cmsFilter("landing_banner06")?.SubDescription3}
                  </ReactReadMoreReadLess></p>

                {/* <p>{aboutdata && aboutdata[2]?.Description}</p> */}
                {/* <a target='blank' href="https://labelm.io/launchpad"><button className='btn getstarted'>Get Started</button></a> */}
              </div>
            </div>
            <div className='col-md-6 d-none d-md-block' data-aos="fade-down" data-aos-duration="1500">
              <div className='bannerimg text-center'>
                {/* <img src={sideimg} /> */}

                {cmsFilter("landing_banner06")?.ImageURI3 == undefined ? "" : < img src={backurl +"/Images/" + cmsFilter("landing_banner06")?.ImageURI3}/>}
              </div>
            </div>
          </div>
        </section>
        <section className=" themesec roadmap resp991 pt-md-5" id="roadmap">
          <div className="">
            <div className="container container_custom">
              <div className='ban-cont text-center'>
                {/* <p className='themeclr'>{featuredata && featuredata[1]?.Title}</p> */}
                {/* <h1 className='innerheading'>{featuredata && featuredata[1]?.Heading}</h1> */}
                <h1 className='innerheading'>{cmsFilter("landing_banner07")?.Title == undefined ? "" : cmsFilter("landing_banner07")?.Title}</h1>
                <p className='smallp'>{cmsFilter("landing_banner07")?.Description == undefined ? "" : cmsFilter("landing_banner07")?.Description}</p>
                {/* <p className='smallp'>{featuredata && featuredata[1]?.Description}</p> */}
              </div>

              <div className="slider_sec_1 fullinerelative">
                <div className='fullline'></div>
                <OwlCarousel items={3}
                  className="owl-theme"
                  loop={false}
                  nav={false}
                  margin={0} autoplay={false} responsive={responsive1} dots={false}>
                  
                    <div className='car-item completed'>
                      <div>

                        <div className='line'></div>
                        <h6 className=' roadmapbtn'>{cmsFilter("landing_banner07")?.SubTitle1 == undefined ? "" : cmsFilter("landing_banner07")?.SubTitle1}</h6>
                        <p>{cmsFilter("landing_banner07")?.SubDescription1 == undefined ? "" : cmsFilter("landing_banner07")?.SubDescription1}</p>

                      </div>
                    </div>
                    <div className='car-item completed'>
                      <div>

                        <div className='line'></div>
                        <h6 className=' roadmapbtn'>{cmsFilter("landing_banner07")?.SubTitle2 == undefined ? "" : cmsFilter("landing_banner07")?.SubTitle2}</h6>
                        <p>{cmsFilter("landing_banner07")?.SubDescription2 == undefined ? "" : cmsFilter("landing_banner07")?.SubDescription2}</p>

                      </div>
                    </div>
                    <div className='car-item completed'>
                      <div>

                        <div className='line'></div>
                        <h6 className=' roadmapbtn'>{cmsFilter("landing_banner07")?.SubTitle3 == undefined ? "" : cmsFilter("landing_banner07")?.SubTitle3}</h6>
                        <p>{cmsFilter("landing_banner07")?.SubDescription3 == undefined ? "" : cmsFilter("landing_banner07")?.SubDescription3}</p>

                      </div>
                    </div>
                    <div className='car-item completed'>
                      <div>

                        <div className='line'></div>
                        <h6 className=' roadmapbtn'>{cmsFilter("landing_banner07")?.SubTitle4 == undefined ? "" : cmsFilter("landing_banner07")?.SubTitle4}</h6>
                        <p>{cmsFilter("landing_banner07")?.SubDescription4 == undefined ? "" : cmsFilter("landing_banner07")?.SubDescription4}</p>

                      </div>
                    </div>

              
               

                  {/* <div className='car-item inprogress'>
                           <div>
                                
                                <div className='line'></div>
                                <h6 className=' roadmapbtn'>Feb-Apr 2022</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                                
                           </div>
                        </div>  
                        <div className='car-item notyetstart'>
                           <div>
                               
                                <div className='line'></div>
                                <h6 className=' roadmapbtn'>Jan-Apr 2022</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                                
                           </div>
                        </div> 
                        <div className='car-item notyetstart'>
                           <div>
                               
                                <div className='line'></div>
                                <h6 className=' roadmapbtn'>Jan-Apr 2022</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                                
                           </div>
                        </div>
                        <div className='car-item notyetstart'>
                           <div>
                               
                                <div className='line'></div>
                                <h6 className=' roadmapbtn'>Jan-Apr 2022</h6>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
                           </div>
                        </div>
                        <div className='car-item notyetstart'>
                           <div>
                                
                                <div className='line'></div>
                                <h6 className=' roadmapbtn'>Jan-Apr 2022</h6>
                                <p>Lemuria project expansion with Real Estate, Explorable, Wearable, Tradeable & Learning</p>
                           </div>
                        </div>  */}

                </OwlCarousel>
              </div>

            </div>
          </div>
        </section>


        <section className='faq ban-cont'>
          <div>
            <h1 className='innerheading text-center'>{cmsFilter("landing_banner08")?.Title == undefined ? "" : cmsFilter("landing_banner08")?.Title}</h1>
            <p className='text-center smallsub mt-3 mb-3'>{cmsFilter("landing_banner08")?.Description == undefined ? "" : cmsFilter("landing_banner08")?.Description}</p>
            <Accordion>
              <div className='row justify-content-center'>
                <div className='col-md-6 mt-4'>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>{cmsFilter("landing_banner08")?.SubTitle1 == undefined ? "" : cmsFilter("landing_banner08")?.SubTitle1}</Accordion.Header>
                    <Accordion.Body>
                    {cmsFilter("landing_banner08")?.SubDescription1 == undefined ? "" : cmsFilter("landing_banner08")?.SubDescription1}
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                <div className='col-md-6   mt-4'>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>{cmsFilter("landing_banner08")?.SubTitle2 == undefined ? "" : cmsFilter("landing_banner08")?.SubTitle2}</Accordion.Header>
                    <Accordion.Body>
                    {cmsFilter("landing_banner08")?.SubDescription2 == undefined ? "" : cmsFilter("landing_banner08")?.SubDescription2}

                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                <div className='col-md-6   mt-4'>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>{cmsFilter("landing_banner08")?.SubTitle3 == undefined ? "" : cmsFilter("landing_banner08")?.SubTitle3}</Accordion.Header>
                    <Accordion.Body>
                    {cmsFilter("landing_banner08")?.SubDescription3 == undefined ? "" : cmsFilter("landing_banner08")?.SubDescription3}
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                <div className='col-md-6   mt-4'>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>{cmsFilter("landing_banner08")?.SubTitle4 == undefined ? "" : cmsFilter("landing_banner08")?.SubTitle4}</Accordion.Header>
                    <Accordion.Body>
                    {cmsFilter("landing_banner08")?.SubDescription4 == undefined ? "" : cmsFilter("landing_banner08")?.SubDescription4}
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                <div className='col-md-6   mt-4'>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>{cmsFilter("landing_banner08")?.SubTitle5 == undefined ? "" : cmsFilter("landing_banner08")?.SubTitle5 }</Accordion.Header>
                    <Accordion.Body>
                    {cmsFilter("landing_banner08")?.SubDescription5 == undefined ? "" : cmsFilter("landing_banner08")?.SubDescription5}
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                <div className='col-md-6 mt-4'>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>{cmsFilter("landing_banner08")?.SubTitle6 == undefined ? "" : cmsFilter("landing_banner08")?.SubTitle6} </Accordion.Header>
                    <Accordion.Body>
                    {cmsFilter("landing_banner08")?.SubDescription6 == undefined ? "" : cmsFilter("landing_banner08")?.SubDescription6}
                      <br></br>  <br></br>
                      Step 1. Download the app. Or stay on the website.
                      <br></br>
                      Step 2. Buy Gruncoin with crypto USDT.
                      <br></br>
                      Step 3. Click on the logo of our partners. And let us know what you like to order or reserve via mail or WhatsApp or telegram or call back.
                    </Accordion.Body>
                  </Accordion.Item>
                </div>
                <div className='col-md-6   mt-4'>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>{cmsFilter("landing_banner08")?.SubTitle7 == undefined ? "" : cmsFilter("landing_banner08")?.SubTitle7}</Accordion.Header>
                    <Accordion.Body> {cmsFilter("landing_banner08")?.SubDescription7 == undefined ? "" : cmsFilter("landing_banner08")?.SubDescription7}</Accordion.Body>
                  </Accordion.Item>
                </div>
              </div>
            </Accordion>

          </div>
        </section>


        <section className='newsletter ban-cont memberlist'>
          <div className='row align-items-center'>
            <div className='col-md-6 mt-3'>
              <h1 className='letter'>Join News Letter</h1>
              <p className='subletter mb-0'>Lorem ipsum dolor sit amet, consetetur sadipscing</p>
            </div>
            <div className='col-md-6 mt-3 text-start'>
              <div className='d-flex'>
              <Form >
                <Form.Group className="mb-0" controlId="formBasicEmail">
                  <Form.Control type="text" placeholder="Enter Your Email" value={email} onChange={(e) => { setEmail(e.target.value)}}/>
               
                </Form.Group>
                
                
              </Form>
              <button className='btn btn getstarted ms-3' onClick={ () => Subscribe()}>Subscribe</button>
              </div>
              <span className='text-danger'>{error?.email}</span>
            </div>
          </div>
        </section>




      </div>
      <Footer />
    </div>
    </> : <> {console.log("enter this2 1111")}</>
    
  )
}

export default Land